import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import product1 from "../images/product-1.png"
import product2 from "../images/product-2.png"
import productCrossShape from "../images/product-cross-shape.webp"
import felisd1 from "../images/Felis-D1-Thumb.webp"
import felisd2 from "../images/Felis-D2-Thumb.webp"
import felisd3 from "../images/Felis-D3-Thumb.webp"
import felisw1 from "../images/Felis-W1-Thumb.webp"
import felisw2 from "../images/Felis-W2-Thumb.webp"
import felisw3 from "../images/Felis-W3-Thumb.webp"
import felisw4 from "../images/Felis-W4-Thumb.webp"
import sbox from "../images/smart-box.png"
import spanel from "../images/smart-panel_1.png"
// import AOS from 'aos'
// import 'aos/dist/aos.css'

class Products extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // AOS.init({
    // })
  }
  render() {
    return <Layout>
      <SEO title="Products" 
      description="Prolacs's new generation products about laundry 4.0."/>
      <Container className='products-page'>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1 style={{ fontSize: '2.5rem' }}>Products</h1>
        </div>
        <Row className='prolacs-products-first-content' data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="4000" data-aos-once="false">
          <Col sm>
            <h1>
              Products <br></br>
              Customized design for your brand & products
         </h1>
            <br></br>
            <p>
              Dust and vibration resistant Laundry Machine Controllers suitable for industrial conditions. We make size and designs according to the needs of our customers. We have solutions from economical models to advanced devices that can go to the cloud.
          </p>
          </Col>
          <Col sm>
            <img src={product1} alt="prolacs-product" />
          </Col>
        </Row>
        <Row data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="4000" data-aos-once="false">
          <Col sm>
            <img src={product2} alt="prolacs-product" />
          </Col>
        </Row>

        <img className='product-cross-shape' src={productCrossShape} alt="product-cross-shape" />
        <div className='prolacs-products-controllers-section' >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Tumble Washer Controllers   <br></br></h1>
          <h3>Customized design for your brand & products</h3>

          <Row className='prolacs-products-row' data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="4000" data-aos-once="false">
            <Col md="auto">
              <Link to="/products/felis-w1">
                <img className='product-border' src={felisw1} alt="prolacs felis-w1" />
                <h3>Felis-W1</h3>
              </Link>

            </Col>
            <Col md="auto">
              <Link to="/products/felis-w2">
                <img className='product-border' src={felisw2} alt="prolacs felis-w2" />
                <h3>Felis-W2</h3>
              </Link>
            </Col>
            <Col md="auto">
              <Link to="/products/felis-w3">
                <img className='product-border' src={felisw3} alt="prolacs felis-w3" />
                <h3>Felis-W3</h3>
              </Link>
           
            </Col>

            <Col md="auto">
              <Link to="/products/felis-w4">
                <img className='product-border' src={felisw4} alt="prolacs felis-w4" />
                <h3>Felis-W4</h3>
              </Link>
            </Col>
            
           
          </Row>
          

          <div className='prolacs-products-controllers-section-2' >
            <h1 className='controllerheadtext' style={{width:"100%"}}>Tumble Dryer Controllers   <br></br></h1>
            <h3>Customized design for your brand & products</h3>
            <Row className='prolacs-products-row' data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="4000" data-aos-once="false">
              <Col md="auto">
                <Link to="/products/felis-d1">
                  <img className='product-border' src={felisd1} alt="prolacs felis-d1" />
                  <h3>Felis-D1</h3>
                </Link>
              </Col>
              <Col md="auto">
                <Link to="/products/felis-d2">
                  <img className='product-border' src={felisd2} alt="prolacs felis-d2" />
                  <h3>Felis-D2</h3>
                </Link>
              </Col>
              <Col md="auto">
                <Link to="/products/felis-d3">
                  <img className='product-border' src={felisd3} alt="prolacs felis-d3" />
                  <h3>Felis-D3</h3>
                </Link>
              </Col>
            </Row>
          </div>
          <div className='prolacs-products-controllers-section-3' data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="4000" data-aos-once="false">
            <h1 className='controllerheadtext' style={{width:"100%"}}>Laundry 4.0 Smart Controllers  <br></br></h1>
            <h3>Customized design for your brand & products</h3>

            <Row className='prolacs-products-row-2' data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="4000" data-aos-once="false">
              <Col md="auto">
                <Link to="/products/smart-box">
                  <img className='product-border' src={sbox} alt="prolacs smart box" />
                  <h3>Smart Box</h3>
                </Link>
              </Col>
              <Col md="auto">
                <Link to="/products/smart-panel">
                  <img className='product-border' src={spanel} alt="prolacs smart panel" />
                  <h3>Smart Panel</h3>
                </Link>
              </Col>
            </Row>
          </div>
        </div>


      </Container>
    </Layout>
  }
};

export default Products
